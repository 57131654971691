<template>
  <div class="ads-component">
    <div>Ads</div>

    <div>Ads</div>

    <div>Ads Come Here</div>
  </div>
</template>

<script>
export default {
  name: "ads-component",
};
</script>